import classnames from "classnames";
import _, {filter, find, first, get, includes, isEmpty, map, replace, size} from 'lodash';
import React from "react";
import ReactPixel from "react-facebook-pixel";
import scroll from "scroll";
import { STEP } from "../../store/giftRedeem/reducer";
import { fireAddItemEvent, fireViewItemEvent } from "../../utils/gtm";
import {
  getParams, navigateTo, removeParams, setParams
} from "../../utils/location";
import {
  getPrice,
  calcToppingOptionPrice,
  getToppingBasePrice,
  toConfiguredMenuItemOrderData,
  toppingCategoryChosenToShortString,
  isParentComponentChosen
} from "../../utils/menuItemOrderData";
import AllergensDecorator from "../allergens-decorator";
import AppContainer from "../AppContainer";
import Button from "../Button";
import Card from "../Card";
import PlateIcon from "../icons/Plate.svg";
import PreferencesIcon from "../icons/Preferences.svg";
import Image from "../image";
import IngredientsSelector from "./IngredientsSelector";
import MenuItemSizeSelector from "./MenuItemSizeSelector";
import OrderItemComponent from "./OrderItemComponent";
import SizeSelector from "./SizeSelector";
import ToppingSelector from "./ToppingSelector";
const page = require("scroll-doc")();

export const SIZE_OPTIONS_COMPONENT_ID = "SIZE_OPTIONS_COMPONENT_ID";
export const TOPPING_COMPONENT_ID = "TOPPING_COMPONENT_ID";

import { getAppMedia, mediaTypes } from "../../utils/media";
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import {Box} from '@mui/system';
import {SLIDE_DELAY_IN_SC} from '../Item';
import * as styles from './index.module.scss';
import Slider from 'react-slick';
import {colors, Grid, LinearProgress} from '@mui/material';
import { withStyles } from '@mui/styles';
import { ca } from "date-fns/locale";


const insertToArr = (arr, index, ...ins) => [
  ...arr.slice(0, index),
  ...ins,
  ...arr.slice(index),
];

const hasMaxForCategory = (toppingCategory) =>
  toppingCategory.maxNumOfMarkedOptions > 0;
const titleRefKeyFromToppingTitle = (toppingCategoryTitle) =>
  `toppingCategory_${toppingCategoryTitle}`;


const menuItemViewStyles = () => ({
  [`customProgressBar${SLIDE_DELAY_IN_SC}s`]: {
        '& .MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate': {
          transitionDuration: `${SLIDE_DELAY_IN_SC}s`,
        }
  },
  customProgressBar0s: {
        '& .MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate': {
          transitionDuration: '0s',
        }
  },
});


class MenuItemView extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        transitionDuration: SLIDE_DELAY_IN_SC,
        delay: SLIDE_DELAY_IN_SC * 1000,
        activeSlideIndex: 0,
        progressPercentage: 0,
        addToOrderClick: false,
        animateErrorElement: false
      };
      this.sliderRef = React.createRef();
    }
  state = { addToOrderClick: false, animateErrorElement: false };
  components = {};

  componentDidMount() {
    const {
      currentBranch: { shouldForgetPastOrderPreferences },
      isEditedByUser,
      isEditMode,
      loadBranchAvailabiltyIfNeeded,
      resetMenuItemConfiguration,
      setState,
    } = this.props;

    const initialConfiguration = this.getInitialConfiguration();

    if (!isEditMode && isEditedByUser && shouldForgetPastOrderPreferences) {
      resetMenuItemConfiguration(initialConfiguration);
    } else {
      setState(initialConfiguration);
    }

    this.removeEditModeFromParamsIfNeeded();
    loadBranchAvailabiltyIfNeeded();
    scroll.top(page);

    this.fireViewItemEventsIfNeeded();
    clearInterval(this.interval);

    const itemsLength = this.props.pageContext?.item?.additionalImages?.length + 1;
    // if no additional images don't start interval
    if (itemsLength > 1) {
      this.interval = setInterval(this.handleInterval, this.state.delay);
    }; 
    
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.state.addToOrderClick
    ) {
      this.setState({ addToOrderClick: false });
    }
    const { stepIndex: prevStepIndex } = getParams(prevProps.location);
    const { stepIndex } = getParams(this.props.location);
    if (stepIndex !== prevStepIndex) {
      console.log("reset back to default");
      this.resetBackToDefault();
    }
  }

  componentWillUnmount() {
    this.cleanRemarksIfNeeded();
    clearTimeout(this.elementAnimation);
    clearInterval(this.interval);
  }

  handleInterval = () => {
    if (!this.sliderRef?.current) return;
    const itemsLength = this.props.pageContext?.item?.additionalImages?.length + 1;

    const nextActiveSlideIndex = (this.state.activeSlideIndex + 1) % itemsLength;

    if (nextActiveSlideIndex === 0) {
      // back to start situation
      this.restartLinesProgresses();
    } else {
      // normal situation
      this.setState({ transitionDuration: SLIDE_DELAY_IN_SC }); // Adjust this method to work with class component state
      this.setState({ progressPercentage: 100 }); // Adjust this method to work with class component state
    }

    this.sliderRef.current.slickGoTo(nextActiveSlideIndex);
    this.setState({ activeSlideIndex: nextActiveSlideIndex });
  };

  restartLinesProgresses = () => {
    // for the css animation to 0 immediately relax
    this.setState({ transitionDuration: 0 });
    this.setState({ progressPercentage: 0 });

    setTimeout(() => {
      // and only then ignite the animation to 100%
      this.setState({ transitionDuration: SLIDE_DELAY_IN_SC }); // for the css animation to start slow progress from 0 immediately again
      this.setState({ progressPercentage: 100 });
    })
  };

  fireViewItemEventsIfNeeded = () => {
    const {
      menuItemOrderData,
      pageContext: {
        business: { trackings },
      },
    } = this.props;

    const fbInitiateViewContent = _.find(
      _.flatMap(_.filter(trackings, { type: "facebook" }), "events"),
      { type: "ViewContent" },
    );
    if (fbInitiateViewContent) {
      const { itemName, menuItemId } = menuItemOrderData;
      ReactPixel.track("ViewContent", {
        content_ids: [menuItemId],
        content_name: itemName,
      });
    }

    fireViewItemEvent(menuItemOrderData); // This is not working when refreshing the page on dev mode.
  };

  removeEditModeFromParamsIfNeeded = () => {
    if (!this.props.isEditMode && getParams(this.props.location).editMode) {
      navigateTo(removeParams(this.props.location, "editMode"));
    }
  };

  registerComponent = (title) => (ref) => {
    this.components[title] = ref;
  };


  handleAddToOrder = () => {
    // refactor price to selector
    const {
      pageContext: {
        business: { currency },
      },
      menuItemOrderData,
      addToOrder,
      menuItemConfiguration,
      isEditMode,
      editItemIndex,
      applyOrderItemEdit,
      editItemFromPayment,
      giftRedeem,
      saveGiftStep,
      location,
      sizeOptions
    } = this.props;
    const params = getParams(location);
    const fromItemId = params.fromItemId;
    const discountRuleId = params.discountRuleId;

    this.setState({ addToOrderClick: true });

    const configuredMenuItemOrderData = toConfiguredMenuItemOrderData(
      menuItemOrderData
    );

    const itemSelectorTitle = _.first(sizeOptions);

    if (giftRedeem.isDuringGiftRedeem || giftRedeem.isDuringDealRedeem) {
      const { currentStepIndex, steps } = giftRedeem;
      const currentStep = steps[currentStepIndex];

      if (
        !_.isEmpty(steps) &&
        currentStep &&
        _.startsWith(location.pathname, currentStep.context.pathname)
      ) {
        saveGiftStep({
          menuItemConfiguration,
          configuredMenuItemOrderData,
          sourceItem: menuItemOrderData.sourceItem,
        });
        this.cleanRemarksIfNeeded();
        const nextStep = steps[currentStepIndex + 1];
        if (nextStep) {
          console.log("navigate to next step:", nextStep.context.pathname);
          navigateTo(
            setParams(nextStep.context.pathname, {
              stepIndex: nextStep.index,
            }),
          );
        } else {
          console.log("navigate to order");
          navigateTo(setParams("/order", { fromItemId }));
        }
      } else {
        // something went wrong...
        console.error('something went wrong in gift flow, navigating to: "/"');
        this.cleanRemarksIfNeeded();
        navigateTo("/");
      }
    } else if (isEditMode) {
      applyOrderItemEdit(editItemIndex, {
        menuItemConfiguration,
        configuredMenuItemOrderData,
        sourceItem: menuItemOrderData.sourceItem,
      });
      this.cleanRemarksIfNeeded();
      editItemFromPayment
        ? navigateTo("/payment")
        : navigateTo(setParams("/order", params));
    } else {
      fireAddItemEvent({
        ...menuItemOrderData,
        price: this.getPrice(),
        quantity: configuredMenuItemOrderData.count,
        currency,
      });

      addToOrder({
        menuItemConfiguration,
        configuredMenuItemOrderData: {
          ...configuredMenuItemOrderData,
        },
        ...(discountRuleId && {
          addedFromUpsale: { discountRuleId },
        }),
        sourceItem: {
          ...menuItemOrderData.sourceItem,
          heldItemsSelectionTitle: _.get(itemSelectorTitle, "heldItemsSelectionTitle"),
        }
      });
      this.cleanRemarksIfNeeded();
      delete params.discountRuleId;
      delete params.search;
      navigateTo(setParams("/order", { ...params, fromItemId }));
    }
  };

  cleanRemarksIfNeeded = () => {
    const {
      currentBranch,
      appStyles: { forgetRemarks },
      menuItemConfiguration,
    } = this.props;

    if (
      this.shouldShowItemComments(currentBranch) &&
      forgetRemarks &&
      menuItemConfiguration
    ) {
      this.props.setState({ remark: null });
    }
  };

  isPriceReady = () => {
    const { menuItemOrderData } = this.props;

    if (menuItemOrderData.optionsOrderData) {
      if (menuItemOrderData.selectedSizeId) {
        return true;
      }
      return false;
    }
    return true; // TODO: change to true after order + configuredMenuItemOrderData refactoring
  };

  onRemarkChange = (remark) => {
    this.props.setState({ remark });
  };

  onQuantityChange = (count) => {
    const { menuItemOrderData } = this.props;
    if (
      !menuItemOrderData.minimumRequiredCount &&
      !menuItemOrderData.maximumAllowedCount
    ) {
      this.props.setState({ count });
    } else if (count < menuItemOrderData.minimumRequiredCount) {
      this.props.setState({ count: menuItemOrderData.minimumRequiredCount });
    } else if (
      menuItemOrderData.maximumAllowedCount &&
      count > menuItemOrderData.maximumAllowedCount
    ) {
      this.props.setState({ count: menuItemOrderData.maximumAllowedCount });
    } else {
      this.props.setState({ count });
    }
  };

  onSizeChange = (selectedSizeId) => this.props.setState({ selectedSizeId });

  onIngredientsChange = (selectedIngredients) => {
    const {
      menuItemOrderData: { ingredients },
    } = this.props;
    const removedIngredients = _.map(
      _.filter(
        ingredients || [],
        (ingredient) => !_.includes(selectedIngredients, ingredient.name),
      ),
      "name",
    );

    this.props.setState({ removedIngredients });
  };

  onOrderItemComponentChange = (title) => ({
    chosenOrderItemComponentOptions,
  }) => {
    this.props.setOrderItemComponent({
      title,
      chosenOrderItemComponentOptions,
    });
  };

  onToppingChange = (categoryTitle, toppingTitle) => ({
    position,
    weight,
    multiplierId,
  }) => {
    this.props.setOrderItemTopping({
      categoryTitle,
      toppingTitle,
      position,
      weight,
      multiplierId,
    });
  };

  onMenuItemSizeChange = (selectedSizeId) => this.props.setSize(selectedSizeId);

  shouldShowMulitpliers = (topping, toppingCategory) => {
    const chosenToppingOption = _.find(topping.toppingOptions, {
      chosen: true,
    });

    return ((toppingCategory.maxNumOfMarkedOptions > 1 || toppingCategory.maxNumOfMarkedOptions < 0) &&
      (toppingCategory.price ||
      toppingCategory.priceAdditional ||
      topping.price ||
      topping.priceAdditional ||
      (chosenToppingOption && chosenToppingOption.price))
    );
  };

  shouldShowItemComments = (currentBranch) => {
    const allowItemCommentsPreference = currentBranch
      ? currentBranch.allowItemComments
      : true;
    if (!allowItemCommentsPreference) return false;

    if (this.hasSizeOptionSelectedOrNotAvailable()) {
      return allowItemCommentsPreference;
    }
    return false;
  };

  hasSizeOptionSelectedOrNotAvailable = () => {
    const { menuItemOrderData } = this.props;
    if (menuItemOrderData.optionsOrderData) {
      return !!menuItemOrderData.selectedSizeId;
    }
    return true;
  };

  shouldShowQuanititySelector = this.hasSizeOptionSelectedOrNotAvailable;

  scrollToErrorComponent = () => {
    const {
      menuItemOrderData: { firstComponentWithError },
      giftRedeem,
    } = this.props;
    if (firstComponentWithError || !this.isPriceReady()) {
      const extraPadding =
        giftRedeem.isDuringGiftRedeem || giftRedeem.isDuringDealRedeem
          ? 100
          : 0;
      const element = firstComponentWithError
        ? this.components[
            firstComponentWithError.isToppingCategory
              ? titleRefKeyFromToppingTitle(firstComponentWithError.title)
              : firstComponentWithError.title
          ]
        : this.components[SIZE_OPTIONS_COMPONENT_ID];

      if (element) {
        const toppingOffset = _.get(
          firstComponentWithError,
          "isToppingCategory",
        )
          ? _.get(this.components[TOPPING_COMPONENT_ID], "offsetTop", 0)
          : 0;
        scroll.top(
          page,
          toppingOffset + element.offsetTop - (76 + extraPadding),
          300,
          () => {
            this.setState({ animateErrorElement: true }, () => {
              clearTimeout(this.elementAnimation);
              this.elementAnimation = setTimeout(
                () => this.setState({ animateErrorElement: false }),
                300,
              );
            });
          },
        );
      }
    } else {
      scroll.top(page);
    }
  };

  renderToppingOptionAsMultiplier = (category, name, index, checked, last, disabled, price) => {
    const { pageContext:{business: { currencySymbol }}, appStyles, disabledColor, rtl } = this.props;

    const pointColor = appStyles.actionColor;
    const style = {
      display: "flex",
      flexGrow: 1,
      paddingTop: 6,
      paddingBottom: 6,
      alignItems: "center",
      justifyContent: "center",
      borderRight: (rtl ? index === 0 : last) || checked ? "none" : "1px solid",
      borderColor: disabled ? disabledColor : pointColor,
      color: checked ? "white" : disabled ? disabledColor : pointColor,
      background: checked
        ? disabled
          ? disabledColor
          : pointColor
        : "transparent",
    };

    return (
      <div
        key={index}
        style={style}
        onClick={() => {
          //uncheck other options
          map(category.toppings, (topping) => {
              this.onToppingChange(category.title, topping.title)({ });
          });
          this.onToppingChange(category.title, name)(checked ? {} : { position: "all", weight: 1 })
        }}
      >
        {replace(name, category.title, "")} {price ? `(${currencySymbol}${price})` : ""}
      </div>
    );
  };

  getInitialConfiguration = () => {
    const { giftRedeem, sizeOptions } = this.props;
    const defaults =
      _.get(sizeOptions, "length") === 1
        ? {
            selectedSizeId: _.get(sizeOptions, "[0].id"),
          }
        : undefined;
    if (giftRedeem.isDuringDealRedeem) {
      const item = _.get(
        giftRedeem.steps[giftRedeem.currentStepIndex],
        "context.item",
      );

      if (item) {
        return item.menuItemConfiguration;
      }
    }

    return defaults;
  };

  resetBackToDefault = () => {
    scroll.top(page);
    if (this.remarkInput) {
      this.remarkInput.clearInput();
    }

    const initialConfiguration = this.getInitialConfiguration();

    this.props.resetMenuItemConfiguration(initialConfiguration);
  };

  getMenuItemSizeOptionsWithInnerMenuPrices = (
    menuItemSizeOptions,
    posInnerMenuId,
  ) =>
    _.map(menuItemSizeOptions, (menuItemSizeOption) => {
      const selectedMenuOption =
        _.find(menuItemSizeOption.posInnerMenuSpecificPrices, {
          posInnerMenuId,
        }) || menuItemSizeOption;
      return { ...menuItemSizeOption, price: selectedMenuOption.price };
    });

  hasNoItemSelectors = ({
    sizeOptions,
    menuItemSizeOptions,
    menuItemOrderData,
    orderItemComponents,
    toppingsComponentCategories,
  }) => {
    const displayedIngredients = _.filter(
      menuItemOrderData.ingredients,
      (ingredient) => !ingredient.isMandatory,
    );
    return (
      !sizeOptions &&
      (_.isEmpty(menuItemSizeOptions) ||
        (!_.isEmpty(menuItemSizeOptions) && menuItemSizeOptions.length <= 1)) &&
      (_.isEmpty(menuItemOrderData.ingredients) ||
        (!_.isEmpty(menuItemOrderData.ingredients) &&
          !displayedIngredients.length)) &&
      _.isEmpty(toppingsComponentCategories) &&
      _.isEmpty(
        _.filter(orderItemComponents, (orderItemComponent) =>
          orderItemComponent.parentComponentTitle
            ? isParentComponentChosen(
                orderItemComponents,
                orderItemComponent.parentComponentTitle,
              )
            : true,
        ),
      )
    );
  };

  shouldDismissPrice = () => {
    const { giftRedeem } = this.props;
    if (!giftRedeem.isDuringGiftRedeem) {
      return false;
    }

    const currentStepType = _.get(
      giftRedeem,
      `steps[${giftRedeem.currentStepIndex}].type`,
    );
    return currentStepType === STEP.CUSTOMIZE_ITEM_YOU_GET_WITH_DISCOUNT;
  };

  getPrice = () => {
    const {
      menuItemOrderData,
      pageContext: { menuMultipliers: multipliers },
      selectedServingOption,
    } = this.props;

    const price = this.isPriceReady()
      ? getPrice(menuItemOrderData, {
          multipliers,
          servingOptionId: selectedServingOption.id,
          posInnerMenuId: this.getPosInnerMenuId(),
        })
      : 0;
    return price;
  };

  getPosInnerMenuId = () => {
    const { currentBranch } = this.props;
    return currentBranch ? currentBranch.innerPosMenuId : null;
  };

  render() {
    const { classes } = this.props;
    const {
      menuItemOrderData,
      appStyles,
      pageContext: {
        business: { currencySymbol, allergens },
        menuMultipliers: multipliers,
        kioskMode,
        item,
        businessAppConfiguration : { indexOfToppingsInItem, enableFavoriteMenuItems }
      },
      isEditMode,
      isEditedByUser,
      isLoggedIn,
      sizeOptions,
      currentBranch,
      selectedServingOption,
      location,
      T,
      keyboardOpen,
      giftRedeem,
    } = this.props;
    const defaultImage = getAppMedia(mediaTypes.defaultItemImage, appStyles);
    const hasDefaultItemImage = defaultImage;

    const shouldForgetPastOrderPreferences = _.get(
      currentBranch,
      "shouldForgetPastOrderPreferences",
    );

    const defaultMenuMultiplierIndex = Array.isArray(multipliers)
      ? _.findIndex(multipliers, { defaultMultiplier: true })
      : 0;

    this.params = getParams(location);

    const posInnerMenuId = this.getPosInnerMenuId();

    const optionsDataPosItemIds = map(menuItemOrderData.optionsOrderData || [menuItemOrderData], 
      (optionsData) => {
        return {
          sizeId: optionsData.menuItemId,
          posItemId:get(first(get(first(get(optionsData, "menuItemServingOptions.servingOptions")), "sizeOptions")), "posItemId")
        }
      }
    );

    const {
      orderItemComponents = [],
      toppingsComponent,
      menuItemServingOptions,
    } = menuItemOrderData.optionsOrderData
      ? _.find(menuItemOrderData.optionsOrderData, {
          id: menuItemOrderData.selectedSizeId,
        }) || {}
      : menuItemOrderData;

    const toppingsComponentCategories = _.get(
      toppingsComponent,
      "toppingsComponentCategories",
    );

    let { sizeOptions: menuItemSizeOptions = null } = (menuItemServingOptions
      ? selectedServingOption
        ? _.find(menuItemServingOptions.servingOptions, {
            servingOptionId: selectedServingOption.id,
          })
        : _.find(menuItemServingOptions.servingOptions, {
            servingOptionType: "pickup",
          }) || menuItemServingOptions.servingOptions[0]
      : { sizeOptions: null }) || { sizeOptions: null };

    const customImageObjectFit = _.get(
      menuItemOrderData,
      "sourceItem.imageObjectFit",
    );
    const hasNoItemSelectors = this.hasNoItemSelectors({
      sizeOptions,
      menuItemSizeOptions,
      menuItemOrderData,
      orderItemComponents,
      toppingsComponentCategories,
    });
    
    const shouldShowItemComments = this.shouldShowItemComments(currentBranch);
    const hasCommentsOnly = hasNoItemSelectors && shouldShowItemComments;
    const hasNoFields = hasNoItemSelectors && !shouldShowItemComments;

    const hasIngredients = !_.isEmpty(menuItemOrderData.ingredients);
    const hasLongDescription =
      _.get(menuItemOrderData.sourceItem.description, "length") > 300;

    const orderItemImageFitStyle =
      customImageObjectFit && customImageObjectFit !== ""
        ? { objectFit: customImageObjectFit === "fit" ? "contain" : "cover" }
        : appStyles.orderItemImageFit && {
            objectFit: appStyles.orderItemImageFit,
          };

    const price = this.getPrice();

    const sumSelectedToppingsPerCategory = _.reduce(
      toppingsComponentCategories,
      (sumSelectedToppingsPerCategory, toppingCategory) => {
        const categorySumSelectedToppings = _.sumBy(
          _.filter(
            _.flatMap(toppingCategory.toppings, "toppingOptions"),
            "chosen",
          ),
          "weight",
        );
        return {
          ...sumSelectedToppingsPerCategory,
          [toppingCategory.title]: categorySumSelectedToppings,
        };
      },
      {},
    );

    const sumOfSelectedToppings = _.sum(
      _.values(sumSelectedToppingsPerCategory),
    );

    const maxSelectedToppings = toppingsComponent
      ? toppingsComponent.maxNumOfMarkedOptionsIncluded <= 0
        ? Number.MAX_SAFE_INTEGER
        : toppingsComponent.maxNumOfMarkedOptionsIncluded
      : null;

    const putIngredientsAfterComponent = _.get(
      menuItemOrderData,
      "putIngredientsAfterComponent",
    );
    const indexOfComponentToPutIngredientsAfter =
      putIngredientsAfterComponent && hasIngredients
        ? _.findIndex(orderItemComponents, {
            title: putIngredientsAfterComponent,
          })
        : -1;
    const menuItemComponentsAndIngerdients = hasIngredients
      ? insertToArr(
          orderItemComponents,
          indexOfComponentToPutIngredientsAfter + 1,
          {
            ...menuItemOrderData.ingredients,
            isIngredients: true,
          },
        )
      : orderItemComponents;

    const needToSliceComponents = !_.isEmpty(toppingsComponentCategories) && !isNaN(indexOfToppingsInItem) && indexOfToppingsInItem != null && menuItemComponentsAndIngerdients && indexOfToppingsInItem > -1 && indexOfToppingsInItem < menuItemComponentsAndIngerdients.length;
    const beforeToppings = !needToSliceComponents  ? menuItemComponentsAndIngerdients : menuItemComponentsAndIngerdients.slice(0, indexOfToppingsInItem);
    const afterToppings = !needToSliceComponents ? [] : menuItemComponentsAndIngerdients.slice(indexOfToppingsInItem);
    const shouldDismissPrice = this.shouldDismissPrice();

    const renderComponents = (componentsAndIngerdients) => {
      return _.map(
        _.filter(
          _.filter(componentsAndIngerdients, (orderItemComponent) =>
            orderItemComponent.parentComponentTitle
              ? isParentComponentChosen(
                  orderItemComponents,
                  orderItemComponent.parentComponentTitle,
                  orderItemComponent.parentOptionIds,
                )
              : true,
          ),
          (orderItemComponent) =>
            orderItemComponent.isIngredients ||
            !_.isEmpty(orderItemComponent.componentOptions),
        ),
        (orderItemComponent) =>
          orderItemComponent.isIngredients ? (
            <IngredientsSelector
              key={orderItemComponent.title+orderItemComponent.index}
              hideMandatory
              ingredients={menuItemOrderData.ingredients}
              color={appStyles.actionColor}
              onChange={this.onIngredientsChange}
              cardBackgroundColor={appStyles.cardBackgroundColor}
              cardBorderRadius={appStyles.cardBorderRadius}
              accentColor={appStyles.accentColor}
              appStyles={appStyles}
              T={T}
              allergens={allergens}
            />
          ) : (
            <OrderItemComponent
              appStyles={appStyles}
              key={orderItemComponent.title}
              refEl={this.registerComponent(orderItemComponent.title)}
              posInnerMenuId={posInnerMenuId}
              orderItemComponent={orderItemComponent}
              onChange={this.onOrderItemComponentChange(
                orderItemComponent.title,
              )}
              firstComponentWithError={
                menuItemOrderData.firstComponentWithError &&
                menuItemOrderData.firstComponentWithError.title ===
                  orderItemComponent.title
              }
              animateErrorElement={this.state.animateErrorElement}
              {...this.props}
            />
          ),
      )
    }
    const renderPrice = () =>
      !giftRedeem.isDuringDealRedeem && (
        <span style={{ whiteSpace: "nowrap" }}>
          {shouldDismissPrice && (
            <small style={{ fontSize: "0.625rem" }}>
              {T("Original Price")}:{" "}
            </small>
          )}
          <span>{`${currencySymbol}${
            appStyles.pricesFixedPoint
              ? price.toFixed(appStyles.pricesFixedPoint)
              : price.toFixed(2)
          }`}</span>
        </span>
      );

    return (
      <AppContainer.Content
        appStyles={appStyles}
        tightBottom
        classNames={classnames(
          styles.MenuItemContainer,
          appStyles.bigDesktopHeader && styles.BigDesktopHeader,
          hasLongDescription &&
            !hasNoItemSelectors &&
            !hasIngredients &&
            styles.HasLongDescription,
        )}
        animate
      >
        <Card
          appStyles={appStyles}
          style={{
            overflow: "hidden",
            ...(appStyles.menuItemImageFocusStyle && {
              border: "none",
              boxShadow: "none",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }),
          }}
          classNames={classnames(
            styles.MenuItemDescription,
            hasNoFields && styles.NoFields,
            hasCommentsOnly && styles.CommentsOnly,
            (giftRedeem.isDuringGiftRedeem || giftRedeem.isDuringDealRedeem) &&
              styles.WithExtraHeaderCompenstation,
            appStyles.menuItemImageFocusStyle && styles.MenuItemImageFocusStyle,
          )}
        >
          <Slider
            arrows={false}
            infinite={true}
            ref={this.sliderRef}
            fade={true}
            onSwipe={
              side => {
                const delta = side === 'left' ? -1 : 1;
                const itemsLength = [item, ...(item.additionalImages||[])].length;
                const nextActiveSlideIndex = (activeSlideIndex + delta) % itemsLength;
                this.restartLinesProgresses();
                this.setState({ activeSlideIndex: nextActiveSlideIndex });
              }
            }
          >
            <Card.Image
              key={"-1"}
              mediaType={
                item.useCategoryImage
                  ? mediaTypes.menuCategory
                  : mediaTypes.menuItem
              }
              mediaKey={item.imageKey}
              sizes="900px"
              imagePreview={item.imagePreview}
              noImageRenderer={
                hasDefaultItemImage
                  ? () => (
                    <Image
                      mediaKey={defaultImage.imageKey}
                      mediaType={mediaTypes.defaultItemImage}
                      imagePreview={defaultImage.imagePreview}
                      sizes="400px"
                      imgStyle={{
                        objectFit: "contain",
                        top: "25%",
                        left: "25%",
                        width: "50%",
                        height: "50%",
                        backgroundColor:
                          appStyles.defaultImageBackgroundColor || "#eee",
                      }}
                      wrapperStyle={{
                        backgroundColor:
                          appStyles.defaultImageBackgroundColor || "#eee",
                        height: appStyles.menuItemImageFocusStyle ? 320 : 180,
                      }}
                    />
                  )
                  : PlateIcon
              }
              className={classnames(
                styles.MenuItemImage,
                appStyles.menuItemImageFocusStyle &&
                styles.MenuItemImageFocusStyle,
              )}
              imgStyle={{ ...orderItemImageFitStyle }}
              wrapperStyle={{
                backgroundColor: appStyles.cardBackgroundColor,
              }}
            />
            {appStyles.alternativeItemImagesShowIn !== 'menu' && map(item.additionalImages, (mediaItem, index) => (
              <Card.Image
                key={index}
                mediaType={mediaTypes.additionalImageMenuItem}
                mediaKey={mediaItem.imageKey}
                sizes="900px"
                className={classnames(
                  styles.MenuItemImage,
                  appStyles.menuItemImageFocusStyle &&
                  styles.MenuItemImageFocusStyle,
                )}
                imgStyle={{ ...orderItemImageFitStyle }}
                wrapperStyle={{
                  backgroundColor: appStyles.cardBackgroundColor,
                }}
              />
            ))}
          </Slider>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-evenly"
          >
            {appStyles.alternativeItemImagesShowIn !== 'menu' && item?.additionalImages?.length && map([item, ...(item.additionalImages||[])], (mediaItem, index) => (
              <Grid
                key={`linear-progress-${mediaItem.key}-${index}`}
                item
                style={{
                  flexGrow: 1,
                  height: "30px",
                  marginBottom: "-21px",
                  paddingTop: "10px",
                  zIndex: 3,
                }}
                onClick={e => {
                  e.stopPropagation();

                  this.setState((prevState) => ({
                    delay: prevState.delay + .1 // restart the progress line interval time
                  }));
                  this.setState({ activeSlideIndex: index });
                  this.sliderRef?.current?.slickGoTo(index);

                  this.setState({ activeSlideIndex: index });
                  this.setState({ transitionDuration: 0 });
                  this.setState({ progressPercentage: 0 });

                  setTimeout(()=> {
                    this.setState({ transitionDuration: SLIDE_DELAY_IN_SC });
                    this.setState({ progressPercentage: 100 });
                  })
                }}
              >
                <LinearProgress
                  className={
                    (this.state.transitionDuration === 0 || index !== this.state.activeSlideIndex)
                      ? classes.customProgressBar0s
                      : classes[`customProgressBar${SLIDE_DELAY_IN_SC}s`]
                  }
                  value={
                    index === this.state.activeSlideIndex
                      ? this.state.progressPercentage
                      : (index < this.state.activeSlideIndex ? 99.99 /* for css animation it's should be not 100*/ : 0)
                  }
                  variant="determinate"
                />
              </Grid>
            ))}
          </Grid>
          <Card.Title
            appStyles={appStyles}
            style={{
              ...appStyles.OrderListCardTitle,
              ...(appStyles.menuItemImageFocusStyle && {
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }),
            }}
          >
            {_.get(menuItemOrderData, "sourceItem.name")}
          </Card.Title>
          <Card.Description
            style={{
              marginBottom: 6,
              ...(appStyles.menuItemImageFocusStyle && {
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }),
            }}
          >
            {_.get(menuItemOrderData, 'sourceItem.description')}
          </Card.Description>
          {!appStyles.hideCaloriesForMenuItems && _.get(menuItemOrderData, 'calories') && <Card.Description
            style={{
              marginBottom: 6,
              ...(appStyles.menuItemImageFocusStyle && {
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }),
              color: appStyles.actionColor
            }}
          >
            {_.get(menuItemOrderData, 'calories')} Cal
          </Card.Description>}
          <Grid container justifyContent="space-between">
            {/* The space-between on the Grid container will push the first item to the left*/}
            <Box><AllergensDecorator
              allergens={_.filter(
                allergens,
                (allergen) =>
                  !_.isEmpty(allergen.id) &&
                  _.includes(item.allergens, allergen.id),
              )}
              appStyles={appStyles}
              wrapperClassNames={styles.AllergensDecorator}
            /></Box>
            {/* The space-between on the Grid container will push the second item to the right*/}
            {enableFavoriteMenuItems && isLoggedIn && <FavoriteButton menuItemId={item.id}/>}
          </Grid>

          <div className={styles.AddToOrderButtonAndQuantitySelectorDesktop}>
            {this.shouldShowQuanititySelector() && (
              <Card.Content classNames={styles.QuantitySelector}>
                <Card.QuantityInput
                  appStyles={appStyles}
                  T={T}
                  onChange={this.onQuantityChange}
                  min={menuItemOrderData.minimumRequiredCount || 1}
                  max={menuItemOrderData.maximumAllowedCount || 100}
                  precision={0}
                  value={
                    menuItemOrderData.count ||
                    menuItemOrderData.minimumRequiredCount ||
                    1
                  }
                />
              </Card.Content>
            )}
            {this.isPriceReady() ? (
              menuItemOrderData.firstComponentWithError ? (
                <AppContainer.Footer.Button
                  onClick={this.scrollToErrorComponent}
                  appStyles={appStyles}
                  tall
                >
                  <span>
                    {T(
                      `${T("Select")} ${T(
                        menuItemOrderData.firstComponentWithError.title?.split("(")?.[0]?.trim(),
                      )}`,
                    )}
                  </span>
                </AppContainer.Footer.Button>
              ) : (
                <AppContainer.Footer.Button
                  onClick={this.handleAddToOrder}
                  spread={!giftRedeem.isDuringDealRedeem}
                  appStyles={appStyles}
                  classNames={styles.AddToOrderButton}
                  loading={this.state.addToOrderClick}
                  tall
                >
                  <span>{isEditMode ? T("Update") : T("Add To Order")}</span>
                  {renderPrice()}
                </AppContainer.Footer.Button>
              )
            ) : (
              <AppContainer.Footer.Button
                onClick={this.scrollToErrorComponent}
                appStyles={appStyles}
                tall
              >
                <span>{T(`Select ${_.get(_.first(sizeOptions), "heldItemsSelectionTitle") ? _.get(_.first(sizeOptions), "heldItemsSelectionTitle") : T("Size")}`)}</span>
              </AppContainer.Footer.Button>
            )}
          </div>
          {!kioskMode && isEditedByUser ? (
            <Card.Content
              style={{
                borderTop: "1px solid #d8d8d8",
                ...(appStyles.menuItemImageFocusStyle && {
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }),
              }}
            >
              {!shouldForgetPastOrderPreferences && (
                <small>{T("We saved your preferences for this item")}</small>
              )}
              <Button
                onClick={this.resetBackToDefault}
                style={{
                  padding: 0,
                  marginTop: 6,
                  ...(appStyles.menuItemImageFocusStyle && {
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }),
                }}
                appStyles={appStyles}
                linkStyle
              >
                <strong>{T("Back to defaults")}</strong>
              </Button>
            </Card.Content>
          ) : !shouldForgetPastOrderPreferences ? (
            <Card.Content
              classNames={styles.PlaceHolder}
              style={{
                ...(appStyles.menuItemImageFocusStyle && {
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }),
              }}
            >
              <div className={styles.PlaceHolderIcons}>
                <PreferencesIcon className={styles.PreferencesIcon} />
              </div>
              <div>
                <small>
                  {T("Your preferences will be saved for this item")}
                </small>
              </div>
            </Card.Content>
          ) : null}
        </Card>
        <div
          className={classnames(
            styles.MenuSelector,
            hasNoFields && styles.NoFields,
            hasCommentsOnly && styles.CommentsOnly,
          )}
        >
          {sizeOptions && sizeOptions.length > 1 && (
            <SizeSelector
              title={_.get(_.first(sizeOptions), "heldItemsSelectionTitle") ? _.get(_.first(sizeOptions), "heldItemsSelectionTitle") : T("Size")}
              sideNote={T("Required")}
              sizeOptions={(giftRedeem && (giftRedeem.isDuringDealRedeem)) ? 
                filter(sizeOptions, sizeOption => 
                    includes(giftRedeem.steps[giftRedeem.currentStepIndex].context.stepPosItemIds, 
                    get(find(optionsDataPosItemIds, optionsDataPosItemId => optionsDataPosItemId.sizeId === sizeOption.menuItemId), "posItemId")))
                  : sizeOptions}
              onChange={this.onSizeChange}
              value={this.props.menuItemOrderData.selectedSizeId}
              T={T}
              brandColor={appStyles.actionColor}
              cardBackgroundColor={appStyles.cardBackgroundColor}
              cardBorderRadius={appStyles.cardBorderRadius}
              accentColor={appStyles.accentColor}
              appStyles={appStyles}
              currencySymbol={currencySymbol}
              firstComponentWithError={!this.isPriceReady()}
              kioskMode={kioskMode}
              animateErrorElement={this.state.animateErrorElement}
              refEl={this.registerComponent(SIZE_OPTIONS_COMPONENT_ID)}
              allergens={allergens}
            />
          )}
          {menuItemSizeOptions && menuItemSizeOptions.length > 1 && (
            <MenuItemSizeSelector
              title={T("Size")}
              sideNote={T("Required")}
              sizeOptions={this.getMenuItemSizeOptionsWithInnerMenuPrices(
                menuItemSizeOptions,
                posInnerMenuId,
              )}
              onChange={this.onMenuItemSizeChange}
              value={_.get(
                menuItemServingOptions,
                "selectedServingOption.selectedMenuSizeId",
              )}
              T={T}
              brandColor={appStyles.actionColor}
              cardBackgroundColor={appStyles.cardBackgroundColor}
              cardBorderRadius={appStyles.cardBorderRadius}
              accentColor={appStyles.accentColor}
              appStyles={appStyles}
              currencySymbol={currencySymbol}
              allergens={allergens}
            />
          )}
          {renderComponents(beforeToppings)}

          {!_.isEmpty(toppingsComponentCategories) && (
            <Card
              appStyles={appStyles}
              refEl={this.registerComponent(TOPPING_COMPONENT_ID)}
            >
              <Card.Header>
                <Card.Header.Title appStyles={appStyles}>
                  {T(toppingsComponent.title)}
                </Card.Header.Title>
                <Card.Header.SideNote>{""}</Card.Header.SideNote>
              </Card.Header>
              <Card.Content tightTop>
                {_.map(
                  _.filter(toppingsComponentCategories, (cat) => {
                    return !_.isEmpty(_.get(cat, "toppings"));
                  }),
                  (toppingCategory) => {
                    const includedDiscount =
                      toppingCategory.maxNumOfMarkedOptionsIncluded > 0
                        ? `(${
                            toppingCategory.maxNumOfMarkedOptionsIncluded
                          } ${T("included")})`
                        : "";

                    const shouldShowToppingsAsMultipliers =
                      toppingCategory.maxNumOfMarkedOptions == 1 &&
                      toppingCategory.minNumOfMarkedComponentOptions == 1 &&
                      toppingCategory.toppings.length > 1 &&
                      toppingCategory.toppings.length <= 5 &&
                      isEmpty(filter(map(toppingCategory.toppings, topping => this.shouldShowMulitpliers(
                        topping,
                        toppingCategory,
                      )))) && 
                      isEmpty(filter(map(toppingCategory.toppings, topping => topping.toppingOptions.length > 1)))
                    ;
                    console.log(toppingCategory.title, shouldShowToppingsAsMultipliers);
                    
                      return (
                        <Card.Segment
                          refEl={this.registerComponent(
                            titleRefKeyFromToppingTitle(toppingCategory.title),
                          )}
                          animateErrorElement={this.state.animateErrorElement}
                          key={toppingCategory.title}
                          title={`${T(
                            toppingCategory.title,
                          )} ${includedDiscount}`}
                          summaryText={toppingCategoryChosenToShortString(
                            toppingCategory,
                            multipliers,
                            T,
                          )}
                          sideNote={[
                            toppingCategory.maxNumOfMarkedOptions > 0 &&
                            toppingCategory.maxNumOfMarkedOptions <=
                              toppingCategory.toppings.length
                              ? `${T("max.")} ${
                                  toppingCategory.maxNumOfMarkedOptions
                                }`
                              : "",
                            toppingCategory.minNumOfMarkedComponentOptions > 0
                              ? `${T("min.")} ${
                                  toppingCategory.minNumOfMarkedComponentOptions
                                }`
                              : "",
                          ].join(" ")}
                          expandedByDefault={
                            (appStyles.expandToppingsIfLessThan > 0 &&
                              appStyles.expandToppingsIfLessThan >
                                toppingCategory.toppings.length) ||
                            (toppingCategory.minNumOfMarkedComponentOptions > 0 && 
                              sumSelectedToppingsPerCategory[
                                toppingCategory.title
                              ] <
                                toppingCategory.minNumOfMarkedComponentOptions
                             )||
                            !appStyles.collapseModifiersByDefault
                          }
                        >
                          {shouldShowToppingsAsMultipliers &&(
                            <div
                            style = {{
                                display: "flex",
                                flexGrow: 1,
                                border: "1px solid",
                                borderColor: appStyles.actionColor,
                                marginBottom: 16,
                            }}>
                             { _.map(
                                toppingCategory.toppings,
                                (topping, toppingIndex) => {

                              return this.renderToppingOptionAsMultiplier(toppingCategory, topping.title, toppingIndex, topping.toppingOptions[0].chosen, toppingIndex === toppingCategory.toppings.length - 1, topping.disabled, topping.toppingOptions[0].price);
                          })}</div>)}

                          {!shouldShowToppingsAsMultipliers &&
                          _.map(
                            toppingCategory.toppings,
                            (topping, toppingIndex) => {
                              return (
                                <ToppingSelector
                                  key={toppingIndex}
                                  topping={topping}
                                  isMaxSelectionForCategory={
                                    (hasMaxForCategory(toppingCategory) &&
                                      sumSelectedToppingsPerCategory[
                                        toppingCategory.title
                                      ] >=
                                        toppingCategory.maxNumOfMarkedOptions) ||
                                    sumOfSelectedToppings >= maxSelectedToppings
                                  }
                                  weightLeftToChoose={Math.min(
                                    hasMaxForCategory(toppingCategory)
                                      ? toppingCategory.maxNumOfMarkedOptions -
                                          sumSelectedToppingsPerCategory[
                                            toppingCategory.title
                                          ]
                                      : Number.MAX_SAFE_INTEGER,
                                    maxSelectedToppings - sumOfSelectedToppings,
                                  )}
                                  multipliers={
                                    this.shouldShowMulitpliers(
                                      topping,
                                      toppingCategory,
                                    ) && multipliers
                                  }
                                  calcToppingOptionPrice={calcToppingOptionPrice(
                                    topping,
                                    toppingCategory,
                                    multipliers,
                                    { posInnerMenuId },
                                  )}
                                  basePrice={getToppingBasePrice(
                                    toppingCategory,
                                    {
                                      posInnerMenuId,
                                    },
                                  )}
                                  onChange={this.onToppingChange(
                                    toppingCategory.title,
                                    topping.title,
                                  )}
                                  defaultMultiplierIndex={
                                    defaultMenuMultiplierIndex
                                  }
                                  appStyles={appStyles}
                                  currencySymbol={currencySymbol}
                                  T={T}
                                />
                              );
                            },
                          )}
                        </Card.Segment>
                      );
                    }
                )}
              </Card.Content>
            </Card>
          )}
          {renderComponents(afterToppings)}
          {shouldShowItemComments && (
            <Card appStyles={appStyles}>
              <Card.Header style={{ display: "flex", flexDirection: "column" }}>
                <Card.Header.Title appStyles={appStyles}>
                  {T("Comments")}
                </Card.Header.Title>
              </Card.Header>
              <Card.Content>
                <div style={{ marginBottom: 6 }}>
                  <small>{T("* Emojis may not reach the kitchen")}</small>
                </div>
                <Card.Note
                  appStyles={appStyles}
                  placeholder={T("Any Comments?")}
                  onChange={this.onRemarkChange}
                  initialValue={menuItemOrderData.remark}
                  refEl={(ref) => (this.remarkInput = ref)}
                />
              </Card.Content>
            </Card>
          )}

          <div className={styles.AddToOrderButtonAndQuantitySelectorMobile}>
            {this.shouldShowQuanititySelector() && (
              <Card appStyles={appStyles}>
                <Card.Content>
                  <Card.QuantityInput
                    appStyles={appStyles}
                    onChange={this.onQuantityChange}
                    mobile={true}
                    snap={true}
                    min={menuItemOrderData.minimumRequiredCount || 1}
                    max={100}
                    precision={0}
                    value={menuItemOrderData.count || 1}
                  />
                </Card.Content>
              </Card>
            )}

            {this.isPriceReady() ? (
              <AppContainer.Footer
                transparentGradient
                center
                relativePosition={keyboardOpen}
                appStyles={appStyles}
              >
                {menuItemOrderData.firstComponentWithError ? (
                  <AppContainer.Footer.Button
                    onClick={this.scrollToErrorComponent}
                    appStyles={appStyles}
                    center
                    style={{ height: 66 }}
                  >
                    <span>
                      {T(
                        `${T("Select")} ${T(
                          menuItemOrderData.firstComponentWithError.title?.split("(")?.[0]?.trim(),
                        )}`,
                      )}
                    </span>
                  </AppContainer.Footer.Button>
                ) : (
                  <AppContainer.Footer.Button
                    onClick={this.handleAddToOrder}
                    spread={!giftRedeem.isDuringDealRedeem}
                    appStyles={appStyles}
                    loading={this.state.addToOrderClick}
                  >
                    <span>{isEditMode ? T("Update") : T("Add To Order")}</span>
                    {renderPrice()}
                  </AppContainer.Footer.Button>
                )}
              </AppContainer.Footer>
            ) : (
              <AppContainer.Footer
                transparentGradient
                center
                relativePosition={keyboardOpen}
                appStyles={appStyles}
              >
                <AppContainer.Footer.Button
                  onClick={this.scrollToErrorComponent}
                  appStyles={appStyles}
                >
                  <span>{T(`Select ${_.get(_.first(sizeOptions), "heldItemsSelectionTitle") ? _.get(_.first(sizeOptions), "heldItemsSelectionTitle") : "Size"}`)}</span>
                </AppContainer.Footer.Button>
              </AppContainer.Footer>
            )}
          </div>
        </div>
      </AppContainer.Content>
    );
  }
}

// Wrap the component with styles
export default withStyles(menuItemViewStyles)(MenuItemView);