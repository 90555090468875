import React from "react";
import _, { sortBy } from "lodash";
import Card from "../Card";

export default ({
  topping,
  isMaxSelectionForCategory,
  multipliers,
  defaultMultiplierIndex,
  currencySymbol,
  calcToppingOptionPrice,
  appStyles = {},
  onChange = _.noop,
  weightLeftToChoose,
  T,
}) => {
  const { actionColor = "blue", disabledTextColor = "#1e1e1e" } = appStyles;
  const handleChange = ({
    selected: selectedToppingIndices,
    selectedMultiplierIndex,
  }) => {
    const selectedToppingPosition = _.first(selectedToppingIndices);
    const selectedToppingOption = _.find(topping.toppingOptions, {
      position: selectedToppingPosition,
    });
    return onChange({
      ..._.pick(selectedToppingOption, ["position", "weight"]),
      multiplierId: Array.isArray(multipliers)
        ? multipliers[selectedMultiplierIndex]
          ? multipliers[selectedMultiplierIndex].id
          : null
        : null,
    });
  };
  const selectedTopping = _.find(topping.toppingOptions, { chosen: true });
  const selectedToppingPosition = _.get(selectedTopping, "position");

  const getPlaceForToppingPosition = (position) => {
    return position  === "all"? 0 : position ===  "right" ? 1 : 2 
  };

  const selectedMultiplierIndex = selectedTopping
    ? _.get(selectedTopping, "multiplierId")
      ? _.findIndex(multipliers, {
          id: _.get(selectedTopping, "multiplierId"),
        })
      : defaultMultiplierIndex
    : defaultMultiplierIndex;

  const disabled = !selectedTopping && isMaxSelectionForCategory;

  return (
    <Card.Content.ToppingSelect
      disabled={disabled}
      disabledColor={disabledTextColor}
      maxWeight={weightLeftToChoose}
      name={T(topping.title)}
      title={
        _.isEmpty(topping.description)
          ? T(topping.title)
          : `${T(topping.title)} - ${T(topping.description)}`
      }
      pointColor={actionColor}
      multipliers={multipliers && multipliers.length ? multipliers : undefined}
      selectedMultiplierIndex={
        selectedMultiplierIndex > -1
          ? selectedMultiplierIndex
          : defaultMultiplierIndex || null
      }
      checked={[selectedToppingPosition]}
      onChange={handleChange}
      rtl={appStyles.rtl}
    >
      {_.reverse(
        _.map(sortBy( topping.toppingOptions, (option)=> getPlaceForToppingPosition(option.position)), (option, index) => {
          const price = selectedTopping
            ? calcToppingOptionPrice(option, selectedMultiplierIndex)
            : 0;

          return (
            <Card.Content.ToppingOption
              disabled={!option.chosen && option.weight > weightLeftToChoose}
              weight={option.weight}
              position={option.position}
              value={option.position || "all"}
              key={option.position}
              label={
                price > 0
                  ? `${currencySymbol}${price.toFixed(
                      appStyles.pricesFixedPoint,
                    )}`
                  : undefined
              }
              unfilled={appStyles.toppingSelectorUnfilled}
            />
          );
        }),
      )}
    </Card.Content.ToppingSelect>
  );
};
